import { FaSearch } from "react-icons/fa";
import "./DraftPlayers.scss";
import PositionFilter from "../../../components/PositionFilter/PositionFilter";
import { useState } from "react";

const DraftPlayers = ({ players, onChange, selectedTab }) => {
  const [, setSearchFilter] = useState("");
  const [positions] = useState([
    "QB",
    "RB",
    "WR",
    "TE",
    "DST",
    "K",
  ]);
  const [selectedPosition, setSelectedPosition] = useState("ALL");

  const handleChangeSearchFilter = (e) => setSearchFilter(e.target.value);

  return (
    <div className="draft-players">
      <div className="draft-players-btn-wrapper">
        <button
          className={`players-button draft-players-btn ${
            selectedTab === "Players" ? "selected" : ""
          }`}
          onClick={() => onChange("Players")}
        >
          Players
        </button>
        <button
          className={`roster-button draft-players-btn ${
            selectedTab === "Roster" ? "selected" : ""
          }`}
          onClick={() => onChange("Roster")}
        >
          Roster
        </button>
        <button
          className={`queue-button draft-players-btn ${
            selectedTab === "Queue" ? "selected" : ""
          }`}
          onClick={() => onChange("Queue")}
        >
          Queue
        </button>
      </div>
      <div className="search-player-wrapper">
        <FaSearch className="search-player-icon" />
        <input
          className="search-player-input"
          type="text"
          placeholder="Search Player"
          onChange={handleChangeSearchFilter}
        />
      </div>
      <div className="position-filter-wrapper">
        <PositionFilter
          positions={positions}
          selectedPos={selectedPosition}
          onChange={setSelectedPosition}
          large
        />
      </div>
      <div className="p">
        {players.map((player) => (
          <p>{player._id}</p>
        ))}
      </div>
    </div>
  );
};

export default DraftPlayers;
