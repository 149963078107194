import './DraftBoardColumnHeader.scss'

const DraftBoardColumnHeader = ({ teamName }) => {
  return (
    <div className='draft-board-column-header'>
        {teamName}
    </div>
  )
}

export default DraftBoardColumnHeader