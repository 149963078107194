import "./DraftBoard.scss";
import DraftBoardColumn from "../DraftBoardColumn/DraftBoardColumn";
import DraftBoardColumnHeader from "../DraftBoardColumnHeader/DraftBoardColumnHeader";

const DraftBoard = ({ teams }) => {
  return (
    <div className="draft-board">
      <div className="draft-board-header">
        {teams.map((team, index) => (
          <DraftBoardColumnHeader key={index} teamName="test" />
        ))}
      </div>
      <div className="draft-board-picks">
        {teams.map((team, index) => (
          <DraftBoardColumn key={index} team={team} teamIndex={index} />
        ))}
      </div>
      <div className="draft-board-header">
        {teams.map((team, index) => (
          <DraftBoardColumnHeader key={index} teamName="test" />
        ))}
      </div>
    </div>
  );
};

export default DraftBoard;
