import "./DraftBoardSquare.scss";

const DraftBoardSquare = ({ player, teamIndex, roundIndex }) => {
  return (
    <div className="draft-board-square">
      <p>{roundIndex + 1}.{teamIndex + 1}</p>
  </div>
  );
};

export default DraftBoardSquare;
