import "./DraftPage.scss";
import Footer from "../../components/Footer/Footer";
import Nav from "../../components/Nav/Nav";
import DraftBoard from "../../features/draft/DraftBoard/DraftBoard";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import {
  useGetDraftByIdQuery,
  useUpdateDraftMutation,
} from "../../features/draft/draftApiSlice";
import { useGetCurrentRankingQuery } from "../../features/rankings/rankingsApiSlice";
import { useEffect, useState } from "react";
import DraftPlayers from "../../features/draft/DraftPlayers/DraftPlayers";

const DraftPage = () => {
  const { draftId } = useParams();
  const [title, setTitle] = useState("");
  const [editingTitle, setEditingTitle] = useState(false);
  const [inputTitle, setInputTitle] = useState();
  const [updateDraft] = useUpdateDraftMutation();
  const [draftablePlayers, setDraftablePlayers] = useState([]);
  const [teams, setTeams] = useState();
  const [selectedPlayersTab, setSelectedPlayersTab] = useState("Players");

  const {
    data: draft,
    isLoading: isDraftLoading,
  } = useGetDraftByIdQuery(draftId);

  const {
    data: players
  } = useGetCurrentRankingQuery("ppr");

  useEffect(() => {
    if (!players?.rankings?.length > 0) return;
    setDraftablePlayers(players?.rankings);
  }, [players]);

  useEffect(() => {
    if (!draft?.settings?.positions) return;
    let teamsTemp = [];
    Array.from(Array(draft.settings.numberOfTeams)).forEach(() => {
      let index = 0;
      let rosterTemp = [];
      Object.entries(draft?.settings?.positions).forEach(
        ([rosterPosition, count]) => {
          Array.from(Array(count)).forEach((i) => {
            rosterTemp.push({
              index: index,
              position: rosterPosition,
              player: null,
            });
            index += 1;
          });
        }
      );
      teamsTemp.push(rosterTemp);
    });
    setTeams(teamsTemp);
  }, [draft]);

  const handleChangeInputTitle = (e) => setInputTitle(e.target.value);
  const handleChangeSelectedPlayersTab = (tab) => setSelectedPlayersTab(tab);

  const updateTitle = () => {
    if (inputTitle === draft?.settings?.title) return;
    setTitle(inputTitle);
    updateDraft({
      title: inputTitle,
      id: draft?._id,
    });
    setEditingTitle(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (!inputTitle.length) {
        return;
      }
      updateTitle();
    }
  };

  return (
    <div className="draft-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Draft | Draftabl</title>
        <meta
          name="description"
          content="Follow your live draft using your custom rankings with our 
          virtual draft board."
        />
      </Helmet>
      <Nav />
      <main>
        <div className="draft-page-inner">
          <section className="draft-left">
            <div className="title-wrapper">
              <input
                type="text"
                onBlur={() => updateTitle(title)}
                onFocus={() => setEditingTitle(true)}
                onChange={handleChangeInputTitle}
                onKeyDown={handleKeyDown}
                className={`title-input-text${
                  editingTitle ? " selected" : " unselected"
                }`}
                value={
                  isDraftLoading
                    ? "Loading..."
                    : !editingTitle
                    ? draft?.settings?.title
                    : inputTitle
                }
              />
            </div>
            <div className="updated-wrapper">
              {draft?.createdAt && (
                <p className="last-update">
                  Created {new Date(draft?.createdAt).getMonth() + 1}/
                  {new Date(draft?.createdAt).getDate()}/
                  {new Date(draft?.createdAt).getFullYear()}
                </p>
              )}
            </div>
            <div className="draft-board-wrapper">
              {teams?.length > 0 && <DraftBoard teams={teams} />}
            </div>
          </section>
          <section className="draft-right">
            <DraftPlayers
              players={draftablePlayers}
              onChange={handleChangeSelectedPlayersTab}
              selectedTab={selectedPlayersTab}
            />
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default DraftPage;
