import { apiSlice } from "../api/apiSlice";

export const draftApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserDrafts: builder.query({
            query: (userId) => `/draft?user=${userId}`,
            keepUnusedDataFor: 5,
            providesTags: ['DraftsList']
        }),
        getDraftById: builder.query({
            query: (draftId) => `/draft/${draftId}`,
            keepUnusedDataFor: 0.001
        }),
        createNewDraft: builder.mutation({
            query: (draftSettings) => ({
                url: '/draft',
                method: 'POST',
                body: draftSettings
            }),
            invalidatesTags: ['Draft']
        }),
        deleteDraft: builder.mutation({
            query: ({ id }) => ({
                url: `/draft/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Draft']
        }),
        updateDraft: builder.mutation({
            query: (body) => ({
                url: '/draft',
                method: 'PUT',
                body
            }),
            invalidatesTags: ['DraftsList']
            // optimistic update
            // async onQueryStarted(body, { dispatch, queryFulfilled }) {
            //     // `updateQueryData` requires the endpoint name and cache key arguments,
            //     // so it knows which piece of cache state to update
            //     const patchResult = dispatch(
            //         customRankingsApiSlice.util.updateQueryData('getCustomRankingById', undefined, draft => {
            //             // The `draft` is Immer-wrapped and can be "mutated" like in createSlice
            //             const ranking = draft.entities[body._id]
            //             if (ranking) ranking.title = body.title
            //         })
            //     )
            //     try {
            //         await queryFulfilled
            //     } catch {
            //         patchResult.undo()
            //     }
            // }
            // async onQueryStarted({ body }, { dispatch, queryFulfilled }) {
            //     const patchResult = dispatch(
            //       apiSlice.util.updateQueryData('getCustomRankingById', body._id, (draft) => {
            //         Object.assign(draft, body)
            //       })
            //     )
            //     try {
            //       await queryFulfilled
            //     } catch {
            //       patchResult.undo()
        
            //       /**
            //        * Alternatively, on failure you can invalidate the corresponding cache tags
            //        * to trigger a re-fetch:
            //        * dispatch(api.util.invalidateTags(['Post']))
            //        */
            //     }
            //   },
            // pessimistic update
            // async onQueryStarted({ body }, { dispatch, queryFulfilled }) {
            //     try {
            //       const { data: updatedRanking } = await queryFulfilled
            //       const patchResult = dispatch(
            //         apiSlice.util.updateQueryData('getCustomRankingById', body._id, (draft) => {
            //           Object.assign(draft, updatedRanking)
            //         })
            //       )
            //     } catch {}
            //   },
        })
    })
})

export const {
    useGetUserDraftsQuery,
    useGetDraftByIdQuery,
    useDeleteDraftMutation,
    useCreateNewDraftMutation,
    useUpdateDraftMutation
} = draftApiSlice
