import "./DraftBoardColumn";
import DraftBoardSquare from "../DraftBoardSquare/DraftBoardSquare";

const DraftBoardColumn = ({ team, teamIndex }) => {
  return (
    <div className="draft-board-column">
      {team.map((rosterPosition, index) => 
        <DraftBoardSquare key={rosterPosition.index} player={rosterPosition.player} teamIndex={teamIndex} roundIndex={index}/>
      )}
    </div>
  )
};

export default DraftBoardColumn;
